import React from "react"
import logo from '../images/logo.svg'

export default () => (
  <footer>
    <div className="container">

        <div className="mid-grid">
          <div className="mid-col text-center">
            <img src={logo} className='logo-icon-footer' alt='Shaun Symm personal website logo' />
            <div className="schema-style" itemscope="" itemtype="http://schema.org/Person">
              <a itemprop="url" href="https://shaunsymm.com">
                <div itemprop="name">
                  <strong>Shaun Symm</strong>
                </div>
              </a>
              <span itemprop="description" className="footer-desc">
                Startup advisor, web designer, product designer and product manager in Sydney, Australia. He is the managing director of <a href="https://launchlab.com.au">startup development studio</a> Launch Lab, where he advises startups and helps them build early stage products.
              </span>
            </div>

            <div className="copyright-wrapper">
              <span>Made with </span>
              <a href="https://figma.com/" rel="nofollow noreferrer" target="_blank">Figma</a> &amp; <a href="https://www.gatsbyjs.com/" rel="nofollow noreferrer" target="_blank">Gatsby.js</a>
              <div className="copyright">
                © {new Date().getFullYear()}
              </div>
            </div>
          </div>
        </div>
    </div>
  </footer>
)
