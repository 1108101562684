import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from '../images/logo.svg'

const Header = ({ siteTitle }) => (
  <header>
    <div className="container">
      <div className="flex flex-ac">
        <img src={logo} className='logo-icon' alt='Shaun Symm personal website logo' />
        <h1 className="logo">
          <Link to="/">
            {siteTitle}
          </Link>
        </h1>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
